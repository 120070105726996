<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Unidade de Saúde</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="entity.unidade_saude_nome" placeholder="" />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="Name">
                          <span class="required">*</span> CEP:
                        </label>
                        <input autocomplete="new-password" id="Name" v-model="entity.cep" placeholder="" type="tel"
                          class="form-control" v-on:keyup="buscar" v-mask="'99999-999'" />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Endereco:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="entity.endereco" placeholder="" />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="Name">
                          <span class="required">*</span> Número:
                        </label>
                        <input autocomplete="new-password" type="number" class="form-control" v-model="entity.numero"
                          placeholder="" id="inputNumero" />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Bairro:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="entity.bairro" placeholder="" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Complemento:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="entity.complemento" placeholder="" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          Ponto de Referência:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="entity.ponto_referencia" placeholder="" />
                      </div>
                    </div>


                    <div class="form-row">
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Telefone:
                        </label>
                        <input autocomplete="new-password" type="tel" id="Name" class="form-control"
                          v-model="entity.telefone" placeholder="" v-mask="'(99) 99999-9999'" />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name">
                          CNES:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="entity.cnes" placeholder="" />
                      </div>



                      
                      <div class="form-group col-md-5">
                        <label for="Name"> Situação da unidade: </label>
                        <select v-model="entity.situacao_unidade" class="form-control">
                          <option :value="1">Ativo</option>
                          <option :value="0">Inativo</option>
                        </select>
                      </div>


                    </div>
                    

                    <div class="form-row">

                      <div class="form-group col-md-4">
                        <label> Estado </label>
                        <v-select
                          :options="ufs"
                          :reduce="(ufs) => ufs.id"
                          :label="'uf_nome'"
                          v-on:input="getEstadoMunicipio"
                          v-model="entity.estado_id"
                          class="vselect"
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Municipio
                        </label>
                        <select class="form-control" v-model="entity.municipio_id">
                          <option v-for="municipio in municipios" :key="municipio.municipio_id"
                            :value="municipio.municipio_id">
                            {{ municipio.municipio_nome }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Tipos de Unidades de Saude
                        </label>
                        <select class="form-control" v-model="entity.tipo_unidade_saude_id">
                          <option v-for="tipo in tipos_unidade_saude" :key="tipo.tipo_unidade_saude_id"
                            :value="tipo.tipo_unidade_saude_id">
                            {{ tipo.tipo_unidade_saude_nome }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/unidade_saude" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      entity: {
        unidade_saude_nome: "",
        endereco: "",
        bairro: "",
        complemento: "",
        cep: "",
        ponto_referencia: "",
        telefone: "",
        cnes: "",
        situacao_unidade: "1",
        numero: "",
        municipio_id: "",
        tipo_unidade_saude_id: "",
        

      },
      
      municipios: [],
      tipos_unidade_saude: [],
      ufs: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "unidade_saude";

      if (self.entity.unidade_saude_id) {
        self.entity._method = "PUT";
        api += "/" + self.entity.unidade_saude_id;
      }

      axios
        .post(api, self.entity)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/unidade_saude");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "unidade_saude/" + id;

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEstadoMunicipio() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getMunicipios: function () {
      const self = this;
      let api = self.$store.state.api + "municipios?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoUnidadeSaude: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_unidade_saude?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_unidade_saude = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.entity.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.entity.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }

            self.entity.endereco = endereco.logradouro;
            self.entity.bairro = endereco.bairro;
            self.entity.complemento = endereco.complemento;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.entity.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipio();
                self.entity.municipio_id = response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumero").focus();
          }
        );
      }
    },
  },
  mounted: function () {
    const self = this;
    self.getMunicipios();
    self.getTipoUnidadeSaude();
    self.getUFs();

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>