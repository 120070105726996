import Crud from '../views/TipoVeiculo/Crud'
import Entity from '../views/TipoVeiculo/Add'

let routes = {
    path: '/tipo_veiculo',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/tipo_veiculo/add',
            name: 'NovoTipoVeiculo',
            component: Entity
        },
        {
            path: '/tipo_veiculo/edit/:id?',
            name: 'EditarTipoVeiculo',
            component: Entity
        },
        {
            path: '/tipo_veiculo',
            name: 'list-tipo-veiculo',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Tipos de Veículos',
                breadcrumb: [
                    {
                        text: 'Tipos de Veículos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
