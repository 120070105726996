import Crud from '../views/Cbo/Crud'
import Entity from '../views/Cbo/Add'

let routes = {
    path: '/cbos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/cbos/add',
            name: 'NewCbo',
            component: Entity
        },
        {
            path: '/cbos/edit/:id?',
            name: 'EditCbo',
            component: Entity
        },
        {
            path: '/cbos',
            name: 'list-cbos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de CBOs',
                breadcrumb: [
                    {
                        text: 'CBOs',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
