import Crud from '../views/UnidadeSaude/Crud'
import Entity from '../views/UnidadeSaude/Add'

let routes = {
    path: '/unidade_saude',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/unidade_saude/add',
            name: 'NewCbo',
            component: Entity
        },
        {
            path: '/unidade_saude/edit/:id?',
            name: 'EditCbo',
            component: Entity
        },
        {
            path: '/unidade_saude',
            name: 'list-unidade-saude',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Unidades de Saúde',
                breadcrumb: [
                    {
                        text: 'Unidades de Saúde',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
