import Entity from '../views/EquipeEmbarcada/View'

let routes = {
    path: '/equipes_embarcadas',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/equipes_embarcadas',
            name: 'Equipes Embarcadas',
            name: 'list-equipes_embarcadas',
            component: Entity,

        },
    ]
};


export default routes
