import Entity from '../views/GerenciarJ4/Entity'

let routes = {
    path: '/gerenciar',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/gerenciar-j4',
            name: 'gerenciar',
            name: 'list-gerenciar-j4',
            component: Entity
        },
    ]
};


export default routes
