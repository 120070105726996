<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{ tipo_desfecho.tipo_desfecho_id ? "Editar" : "Novo" }}
                Desfecho
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Desfecho:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="tipo_desfecho.tipo_desfecho" placeholder="" />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="Name"> Status: </label>
                        <select v-model="tipo_desfecho.status" class="form-control">
                          <option :value="1">Ativo</option>
                          <option :value="0">Inativo</option>
                        </select>
                      </div>
                    </div>


                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/tipo_desfecho" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      tipo_desfecho: {
        tipo_desfecho: "",
        status: "1",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_desfechos";

      if (self.tipo_desfecho.desfecho_id) {
        self.tipo_desfecho._method = "PUT";
        api += "/" + self.tipo_desfecho.desfecho_id;
      }

      axios
        .post(api, self.tipo_desfecho)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/tipo_desfechos");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelamentos: function (id) {
      const self = this;
      const api = self.$store.state.api + "tipo_desfechos/" + id;

      axios
        .get(api)
        .then((response) => {
          self.tipo_desfecho = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getCancelamentos(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>