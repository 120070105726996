import Crud from '../views/Transporte/Programacao/Table'
import Entity from '../views/Transporte/Programacao/Entity'
import Calendario from '../views/Transporte/Programacao/Calendario'

let routes = {
    path: '/solicitacoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/programacoes',
            name: 'list-programacoes',
            component: Crud,
            meta: {
                pageTitle: 'Programações',
                breadcrumb: [
                    {
                        text: 'Programações',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programacoes/programacao/:id?',
            name: 'ProgramarSolicitacao',
            component: Entity
        },
        {
            path: '/calendario',
            name: 'calendario',
            component: Calendario
        },
    ]
};


export default routes
