import Crud from '../views/Veiculo/Crud'
import Entity from '../views/Veiculo/Add'

let routes = {
    path: '/veiculo',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/veiculo/add',
            name: 'NovoVeiculo',
            component: Entity
        },
        {
            path: '/veiculo/edit/:id?',
            name: 'EditarVeiculo',
            component: Entity
        },
        {
            path: '/veiculo',
            name: 'list-veiculo',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Veículos',
                breadcrumb: [
                    {
                        text: 'Veículos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
