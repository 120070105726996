import Crud from '../views/Classificacao/Crud'
import Entity from '../views/Classificacao/Add'

let routes = {
    path: '/classificacoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/classificacoes/add',
            name: 'NewClassificacao',
            component: Entity
        },
        {
            path: '/classificacoes/edit/:id?',
            name: 'EditClassificacao',
            component: Entity
        },
        {
            path: '/classificacoes',
            name: 'list-classificacoes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Classificação de Ocorrência',
                breadcrumb: [
                    {
                        text: 'Classificação de Ocorrência',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
