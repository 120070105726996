import Crud from '../views/TestePerformance/Table'

let routes = {
    path: '/teste_performance',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/teste_performance',
            name: 'list-teste-performance',
            component: Crud,

            meta: {
                pageTitle: 'Lista de Testes',
                breadcrumb: [
                    {
                        text: 'Testes',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
