import Crud from '../views/TipoUnidadeMedida/Crud'
import Entity from '../views/TipoUnidadeMedida/Add'

let routes = {
    path: '/tipo_unidade_medida',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/tipo_unidade_medida/add',
            name: 'NewCbo',
            component: Entity
        },
        {
            path: '/tipo_unidade_medida/edit/:id?',
            name: 'EditCbo',
            component: Entity
        },
        {
            path: '/tipo_unidade_medida',
            name: 'list-tipo-unidade-medida',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Unidades de Medidas',
                breadcrumb: [
                    {
                        text: 'Tipos de Unidades de Medidas',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
