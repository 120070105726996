let env = {};

//env.baseURL = 'http://18.229.133.38:82/api/v1/';
//env.normalURL = 'http://18.229.133.38:82/';
env.mode = 'homolog';

// env.baseURL = 'http://api.transporte_sanitario_diadema.it4d.com.br/api/v1/';
// env.normalURL = 'http://api.transporte_sanitario_diadema.it4d.com.br/';

// env.baseURL = 'http://api.transporte_sanitario_homolog.it4d.com.br/api/v1/';
// env.normalURL = 'http://api.transporte_sanitario_homolog.it4d.com.br/';

// env.baseURL = 'http://api.transporte_rio.it4d.com.br/api/v1/';
// env.normalURL = 'http://api.transporte_rio.it4d.com.br/';

env.baseURL = 'https://api.tihrio-homolog.ares-saude.com.br/api/v1/';
env.normalURL = 'https://api.tihrio-homolog.ares-saude.com.br/';

export default env
