<template>
  <div>
    <div class="main-content">
      <div class="card">
        <div class="card-header bg-primary text-white">
          <div class="card-title">Equipes Embarcadas</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" v-for="equipe in equipes" :key="equipe.equipe_id">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center">
                    <h4 class="border-bottom pb-1 mb-1">
                      {{ equipe.equipe_nome }} - ({{
                        equipe.veiculo.placa_veiculo
                      }})
                    </h4>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn btn-primary"
                      data-toggle="modal"
                      @click="getTripulantes(equipe.equipe_id)"
                      data-target="#modalTripulacao"
                    >
                      Visualizar Tripulação
                    </button>
                  </div>
                  <div
                    class="modal fade"
                    id="modalTripulacao"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modalTripulacao"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="card-header bg-primary text-white">
                          <h5 class="modal-title">
                            Equipe Embarcada na VTR:
                            {{
                              tripulacoes.length > 0
                                ? tripulacoes[0].equipe.equipe_nome
                                : ""
                            }}
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true" style="color: #fff"
                              >&times;</span
                            >
                          </button>
                        </div>
                        <div class="modal-body">
                          <h4>Tripulantes:</h4>
                          <div v-if="tripulacoes.length > 0">
                            <div
                              v-for="tripulacao in tripulacoes"
                              :key="tripulacao.tripulacao_id"
                              class="mb-1"
                            >
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div>
                                  <h5>
                                    <strong>Nome:</strong>
                                    {{ tripulacao.tripulante.tripulante_nome }}
                                  </h5>
                                  <p class="mb-0">
                                    <strong>Cargo:</strong>
                                    {{
                                      tripulacao.tripulante.tipo_profissional
                                        .tipo_profissional_nome
                                    }}
                                  </p>
                                  <p class="mb-0">
                                    <strong>Último acesso em:</strong>
                                    {{
                                      tripulacao &&
                                      tripulacao.usuario &&
                                      tripulacao.usuario.data_ultimo_acesso
                                        ? tripulacao.usuario
                                            .data_ultimo_acesso_f
                                        : "Sem novo acesso até o momento"
                                    }}
                                  </p>
                                  <p class="mb-0">
                                    <strong>Embarcado em:</strong>
                                    {{ tripulacao.data_gravacao_f }}
                                    <span
                                      v-if="
                                        tripulantes_24h.includes(tripulacao)
                                      "
                                      class="badge badge-danger"
                                    >
                                      Embarcado a mais de 24h nesta equipe
                                    </span>
                                  </p>
                                  <p
                                    class="mb-0"
                                    v-if="tripulacao.checklist_recente !== null"
                                  >
                                    <span
                                      v-if="
                                        tripulacao.checklist_recente === true
                                      "
                                    >
                                      <strong>Checklist feito:</strong>
                                      <i
                                        class="fas fa-circle fa-lg"
                                        style="color: green; margin-left: 5px"
                                      ></i>
                                    </span>
                                    <span v-else>
                                      <strong>Checklist feito:</strong>
                                      <i
                                        class="fas fa-circle fa-lg"
                                        style="color: red; margin-left: 5px"
                                      ></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <hr class="mt-2 mb-2" />
                            </div>
                          </div>
                          <div v-else>
                            <p>Nenhum tripulante embarcado.</p>
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Fechar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";

export default {
  data() {
    return {
      equipes: [],
      equipe: {
        equipe_id: "",
      },
      veiculos: [],
      tripulacoes: [],
      checklists_veiculo: [],
      checklist_recente: false,
    };
  },
  computed: {
    tripulantes_24h() {
      const agora = new Date();
      return this.tripulacoes.filter((tripulacao) => {
        const dataGravacao = new Date(tripulacao.data_gravacao);
        const diferencaHoras = (agora - dataGravacao) / (1000 * 60 * 60);
        return diferencaHoras > 24;
      });
    },
  },

  methods: {
    getEquipes: function () {
      const self = this;
      let api =
        self.$store.state.api +
        "equipes?paginated=false&status=1&with[]=veiculo";

      self.equipes = [];

      axios
        .get(api)
        .then((response) => {
          self.equipe = response.data.data[0];
          self.equipes = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },

    getTipoProfissional: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_profissional?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getTripulantes: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "tripulacaos?paginated=false&status=1&with[]=tripulante&with[]=usuario&with[]=equipe&equipe_id=" +
        id;

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.tripulacoes = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
  },
  mounted: function () {
    const self = this;
    self.$loading(true);
    self.getEquipes();
    // self.getTipoProfissional();
    // self.getTiposPosicao();
    // self.getEntity();
    // self.getVeiculos();
    // self.getTiposVeiculo();
  },
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.button-tripulante {
  border: solid #bebebe 1px;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;
  width: 75% !important;
}

.block {
  background-color: white;
  border-radius: 3px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.block-title {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}

.block-title-active {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
  animation: animate 1.5s linear infinite;
}

.border-block {
  border: 1px #15488a solid;
  border-radius: 3px;
  margin: 7px;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.card-title {
  font-size: 1.5rem;
}

.card-body {
  padding: 1.25rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.modal-title {
  font-size: 1.25rem;
  color: white;
}

.modal-body {
  padding: 1.25rem;
}

.modal-footer {
  justify-content: center;
  padding: 1.25rem;
}
</style>