<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Veículo</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Nome do Veículo:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.nome_veiculo"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Placa do Veículo:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.placa_veiculo"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name"> Marca do Veículo: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.marca_veiculo"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name"> Modelo do Veículo: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.modelo_veiculo"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name"> Cor do Veículo: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.cor_veiculo"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name"> CNES do Veículo: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.cnes"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name"> Observação: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.observacao"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name"> Tipo do Veículo: </label>
                        <select
                          class="form-control"
                          v-model="entity.tipo_veiculo_id"
                        >
                          <option
                            v-for="tipo in tipos_veiculo"
                            :key="tipo.tipo_veiculo_id"
                            :value="tipo.tipo_veiculo_id"
                          >
                            {{ tipo.tipo_veiculo_nome }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name"> Status: </label>
                        <select v-model="entity.status" class="form-control">
                          <option :value="1">Ativo</option>
                          <option :value="0">Inativo</option>
                        </select>
                      </div>
                    </div>

                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/veiculo" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      entity: {
        nome_veiculo: "",
        placa_veiculo: "",
        marca_veiculo: "",
        modelo_veiculo: "",
        cor_veiculo: "",
        cnes: "",
        observacao: "",
        tipo_veiculo_id: "",
        status: "1",
      },
      tipos_veiculo: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "veiculo";

      if (self.entity.veiculo_id) {
        self.entity._method = "PUT";
        api += "/" + self.entity.veiculo_id;
      }

      axios
        .post(api, self.entity)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/veiculo");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "veiculo/" + id;

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposVeiculo: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_veiculo?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_veiculo = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getTiposVeiculo();

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>