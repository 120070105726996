import Crud from '../views/Transporte/CanceladoFinalizado/Table'
let routes = {
    path: '/cancelados_finalizados',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/cancelados_finalizados',
            name: 'list-cancelados_finalizados',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Finalizados e Cancelados',
                breadcrumb: [
                    {
                        text: 'Finalizados e Cancelados',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
