<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-4">
                <label>Qual módulo do erro?</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  placeholder="Ex. Dashboard, Usuário, etc"
                  v-model="ticket.method_error"
                />
              </div>
              <div class="col-md-4">
                <label>Onde foi o erro?</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  placeholder="Ex. Criar, Editar, etc..."
                  v-model="ticket.request_error"
                />
              </div>
              <div class="col-md-4">
                <label>Data e hora do erro</label>
                <input v-model="ticket.data_error" id="name" class="form-control" type="datetime-local" />
              </div>
              <div class="col-md-12 mt-1">
                <label>Por favor, descreva o erro abaixo com o máximo de detalhes possível</label>
                <textarea class="form-control" rows="4" cols="50" v-model="ticket.description_error"></textarea>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <hr />
              <router-link to="./" class="btn btn-back mr-1">
                <i class="fa fa-arrow-left" />
                Voltar
              </router-link>
              <a class="btn btn-add" @click="save">
                Salvar
                <i class="fa fa-save" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      ticket: {
        system_error_id: "1",
        user: "0",
        dev_acting: "0",
        dev_accompany: "0",
        status: "0",
        url_system: "{}",
        json: "{}",
      },
      projects: [],
      users: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "ticket";

      self.ticket.user = this.$store.state.user.usuario_nome + ": " + this.$store.state.user.usuario_id;

      self.$http
        .post(api, self.ticket)
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          this.$router.push("./");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
   
  },

  mounted: function () {},
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>