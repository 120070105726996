<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'usersCrud'"
          crudName="Municipios"
          newText="Novo"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "municipiosTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "municipio_id",
        "municipio_nome",
        "municipio_codigo",
        "uf_id",
        "status",
        "cod_uf",
        "actions",
      ],
      tableData: [
        "municipio_id",
        "municipio_nome",
        "municipio_codigo",
        "uf_id",
        "cod_uf",
        "status",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: [
          "municipio_id",
          "municipio_nome",
          "municipio_codigo",
          "status",
          "uf_id",
          "cod_uf",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          municipio_id: "ID",
          municipio_nome: "Nome do Municipio",
          municipio_codigo: "Código do Municipio",
          uf_id: "UF",
          status: "Status",
          cod_uf: "Código UF",
          actions: "Ações",
        },
        sortable: [
          "municipio_id",
          "municipio_nome",
          "municipio_codigo",
          "status",
        ],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 1,
              text: "Ativo",
            },
            {
              id: 0,
              text: "Inativo",
            },
          ],
        },
        templates: {
          uf_id: function (h, row, index) {
            return row.uf_id ? row.uf.uf_nome : "Sem Nome";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["uf"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "municipios",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    query: function (query) {
      let columns = {
        municipio_id: "municipio_id",
        municipio_nome: "municipio_nome",
        municipio_codigo: "municipio_codigo",
        uf_id: "uf[uf_nome]",
        status: "status",
        cod_uf: "cod_uf",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>