<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center">
        <span class="mr-1" style="color: #8800aa">
          <span class="badge" style="background: #8800aa; border-radius: 100px">
            R
          </span>
          Atendimento recorrente
        </span>
        <span class="mr-1" style="color: #aa5588">
          <span class="badge" style="background: #aa5588; border-radius: 100px">
            1º
          </span>
          Primeira recorrencia
        </span>
        <span>
          <toggle-button
            @change="showFinisheds"
            :labels="{ checked: 'Sim', unchecked: 'Não' }"
            :width="55"
            v-model="showFinished"
          />
          <span> Exibir finalizados </span>
        </span>
        <span>
          <toggle-button
            class="ml-1"
            @change="atualizarProgramacao"
            :labels="{ checked: 'Sim', unchecked: 'Não' }"
            :width="55"
          />
          <span> Atualizar Automaticamente </span>
        </span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <label>Número da Solicitação</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.numero_solicitacao"
        />
      </div>
      <div class="col-md-2">
        <label>Data da Solicitação</label>
        <input
          type="date"
          class="form-control"
          v-model="filters.data_solicitacao"
        />
      </div>
      <div class="col-md-2">
        <label>Data do Transporte</label>
        <input
          type="date"
          class="form-control"
          v-model="filters.data_procedimento"
        />
      </div>
      <div class="col-md-3">
        <label>Tipo de Transporte</label>
        <v-select
          :options="tipos_transporte"
          label="tipo_transporte_nome"
          v-model="filters.tipo_transporte_id"
          :reduce="(tipo) => tipo.tipo_transporte_id"
        ></v-select>
      </div>
      <div class="col-md-3">
        <label>Nome do Paciente</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.nome_paciente"
        />
      </div>
      <div class="col-md-4 mt-1">
        <label>Origem</label>
        <input type="text" class="form-control" v-model="filters.origem" />
      </div>
      <div class="col-md-4 mt-1">
        <label>Destino</label>
        <input type="text" class="form-control" v-model="filters.destino" />
      </div>
      <div class="col-md-3 mt-1">
        <label>Status</label>
        <select class="form-control" v-model="filters.status_solicitacao">
          <option value=""></option>
          <option value="3">Finalizado</option>
          <option value="4">Cancelado</option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <button class="btn btn-primary" @click="getSolitacoes">Filtrar</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-success" @click="limparFiltros">
          Limpar filtros
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card content-crud">
          <div class="ibox">
            <div class="ibox-title" style="padding: 16px">
              <h3>Solicitações</h3>
            </div>
            <div class="ibox-content">
              <div class="table-responsive" style="padding: 10px">
                <table
                  class="VueTables__table table table-striped table-bordered table-hover"
                  style="padding: 10px"
                >
                  <thead>
                    <tr>
                      <th scope="col">NÚMERO SOLICITACAO</th>
                      <th scope="col">DATA DA SOLICITAÇÃO</th>
                      <th scope="col">DATA DO TRANSPORTE</th>
                      <th scope="col">TIPO DE TRANSPORTE</th>
                      <th scope="col">VTR Empenhada</th>
                      <th scope="col">NOME PACIENTE</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">AÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="solicitacao in solicitacoes"
                      :key="solicitacao.numero_solicitacao"
                    >
                      <td>{{ solicitacao.numero_solicitacao }}</td>
                      <td>{{ solicitacao.data_solicitacao_f }}</td>
                      <td>{{ solicitacao.data_procedimento_f }}</td>
                      <td>
                        {{ solicitacao.tipo_transporte.tipo_transporte_nome }}
                      </td>
                      <td>
                        {{
                          solicitacao.veiculo
                            ? solicitacao.veiculo.veiculo.nome_veiculo
                            : "Não informado"
                        }}
                      </td>
                      <td>
                        {{
                          solicitacao.paciente
                            ? solicitacao.paciente.nome_paciente
                            : "Não informado"
                        }}
                      </td>
                      <td v-html="status_solicitacao(solicitacao)"></td>
                      <td>
                        <router-link
                          :to="'/eventos_desfechos/view/' + solicitacao.id"
                          title="Visualizar"
                          class="btn btn-crud view"
                        >
                          <i class="fas fa-eye"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-md-6 mt-1 mx-auto d-flex justify-content-center align-items-center"
                >
                  <pagination
                    :records="total"
                    :options="options"
                    :per-page="15"
                    @paginate="getSolitacoes"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { Pagination } from "vue-pagination-2";

export default {
  data: function () {
    return {
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      autoRefreshAtivado: false,
      pages: [],
      solicitacoes: [],
      showFinished: false,
      filters: {
        numero_solicitacao: "",
        data_solicitacao: "",
        data_procedimento: "",
        tipo_transporte_id: "",
        nome_paciente: "",
        nome_veiculo: "",
        origem: "",
        destino: "",
        status_solicitacao: "",
      },
      tipos_transporte: [],
    };
  },
  components: {
    BaseCrud,
    vSelect,
    Pagination,
  },

  methods: {
    getSolitacoes(page) {
      const self = this;

      self.page = page || 1;

      let api =
        self.$store.state.api +
        "solicitacoes/eventos_desfechos?page=" +
        self.page;

      self.$loading(true);
      axios
        .post(api, self.filters)
        .then((response) => {
          self.solicitacoes = response.data.data;
          self.total = response.data.total;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    limparFiltros() {
      for (let key in this.filters) {
        this.filters[key] = "";
      }
      this.getSolitacoes();
    },

    status_solicitacao: function (row) {
      if (row.status_solicitacao == 0) {
        return "<h5><span class='badge badge-warning'>Solicitado</span></h5>";
      }
      if (row.status_solicitacao == 1) {
        return "<h5><span class='badge badge-info'>Aguardando</span></h5>";
      }
      if (row.status_solicitacao == 2) {
        return "<h5><span class='badge badge-primary'>Despachado</span></h5>";
      }
      if (row.status_solicitacao == 3) {
        return "<h5><span class='badge badge-success'>Finalizado</span></h5>";
      }
      if (row.status_solicitacao == 4) {
        return "<h5><span class='badge badge-danger'>Cancelado</span></h5>";
      }
    },
    getTiposTransporte: function () {
      const self = this;
      let api =
        self.$store.state.api + "tipo_transportes?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_transporte = response.data.data;

          if (self.tipos_transporte.length == 1) {
            self.entity.tipo_transporte_id =
              self.tipos_transporte[0].tipo_transporte_id;

            if (self.tipos_transporte[0].tipo_solicitacao == 0) {
              self.getMotivos();
              self.inverse = true;
              self.estilo_transporte = 0;
              self.page = 2;
              self.setPages();
            }
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    atualizarProgramacao(valor) {
      this.autoRefreshAtivado = valor;
      if (valor) {
        this.iniciarTemporizadorAutoRefresh();
      } else {
        this.pararTemporizadorAutoRefresh();
      }
    },
    iniciarTemporizadorAutoRefresh() {
      if (this.autoRefreshAtivado) {
        this.temporizadorAutoRefresh = setInterval(() => {
          this.getProgramados();
        }, 10000);
      }
    },
    pararTemporizadorAutoRefresh() {
      clearInterval(this.temporizadorAutoRefresh);
    },
    filtered_dp(date) {
      this.data_procedimento = date;
    },
    filtered_ds(date) {
      this.data_solicitacao = date;
    },
    showFinisheds(res) {
      const self = this;
      self.$refs.programacaoCrud.$refs.table.refresh();
    },
    approving(props, row, index) {
      const self = this;
      this.$router.push("/aprovacoes/aprovacao/" + props.row.id);
    },
  },
  mounted() {
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters"));
    if (savedFilters) {
      this.filters = savedFilters;
    }

    this.iniciarTemporizadorAutoRefresh();
    this.getSolitacoes();
    this.getTiposTransporte();
  },
  watch: {
    filters: {
      handler(newFilters) {
        localStorage.setItem("savedFilters", JSON.stringify(newFilters));
      },
      deep: true,
    },
  },
  destroyed() {
    this.pararTemporizadorAutoRefresh();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>