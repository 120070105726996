// import Entity from '../views/Frota/Entity'
import NewEntity from '../views/Frota/NewEntity'

let routes = {
    path: '/frota',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        // {
        //     path: '/frota',
        //     name: 'Frota',
        //     name: 'list-frota',
        //     component: Entity
        // },
        {
            path: '/frota-new',
            name: 'Frota',
            name: 'list-frota-new',
            component: NewEntity
        },
    ]
};


export default routes
