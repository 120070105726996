<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Log nº {{ entity.log_id.toString().padStart(6, '0') }}</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 mb-1">
                  <label>CRUD e ID</label>
                  <input type="text" class="form-control" v-model="entity.crud_id" disabled>
                </div>
                <div class="col-md-6 mb-1">
                  <label>Data</label>
                  <input type="text" class="form-control" v-model="entity.data_criacao_f" disabled>
                </div>
                <div class="col-md-6 mb-1">
                  <label>Usuário</label>
                  <input type="text" class="form-control" v-model="entity.user.usuario_nome" disabled>
                </div>
                <div class="col-md-6 mb-1">
                  <label>Operação</label>
                  <input type="text" class="form-control" v-model="entity.operacao_f" disabled>
                </div>
                <div class="col-md-12 mb-1">
                  <label>Mensagem</label>
                  <textarea class="form-control" rows="5" v-model="entity.mensagem" disabled></textarea>
                </div>
                <div class="col-md-12 mb-1">
                  <label>JSON</label>
                  <div class="input-group">
                    <textarea id="json" class="form-control" aria-describedby="json_btn" rows="5" v-model="entity.json" disabled></textarea>
                    <div class="input-group-append">
                      <button :class="'input-group-text btn btn-' + (clipboard ? 'secondary' : 'primary')" id="json_btn" @click="copyJson()"><span v-html="text_clipboard"></span></button>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-1">
                  <hr style="margin-top: -4px" />
                  <div class="block text-right">
                    <router-link
                      to="/logs_genericos"
                      class="btn btn-back mr-1"
                    >
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      entity: {
        created_by: "",
        crud_id: "",
        data_criacao_f: "",
        json: "",
        mensagem: "",
        operacao_f: "",
        request_id: "",
        user: {
          usuario_nome: "",
        },
      },
      clipboard: 0,
      text_clipboard: "Copiar <br> JSON",
    };
  },
  methods: {
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "logs_genericos/" + id + '?with[]=user';

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];
          self.entity.crud_id = self.entity.crud + '_id: ' + self.entity.request_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    copyJson: function(){
      const self = this;

      self.clipboard = 1;
      self.text_clipboard = 'JSON <br> Copiado!';

      var copyText = document.getElementById("json");
      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      setTimeout(function(){
        self.clipboard = 0;
        self.text_clipboard = "Copiar <br> JSON";
      }, 10000);
    }
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
textarea{
  resize: none;
}
</style>