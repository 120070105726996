import Crud from '../views/Transporte/Tarm/Table'
import Entity from '../views/Transporte/Tarm/Entity'
let routes = {
    path: '/eventos_desfechos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/eventos_desfechos',
            name: 'list-eventos_desfechos',
            component: Crud,

            meta: {
                pageTitle: 'Lista de Eventos Sem Desfechos',
                breadcrumb: [
                    {
                        text: 'Eventos Sem Desfechos',
                        active: true,
                    },
                ],
            },
        },

        {
            path: '/eventos_desfechos/view/:id?',
            name: 'VisualizarTransporte',
            component: Entity
        },
    ]
};


export default routes
