import Crud from '../views/Rih/Table'

let routes = {
    path: '/rihs',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/rihs',
            name: 'list-rihs',
            component: Crud,

            meta: {
                pageTitle: 'Lista de RIHs',
                breadcrumb: [
                    {
                        text: 'RIHs Vinculados',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
