import Vue from 'vue'
import VueRouter from 'vue-router'

import User from './user'
import Solicitacao from './solicitacao'
import TipoAvaliacaoClinica from './tipo_avaliacao_clinica'
import TipoAcompanhante from './tipo_acompanhante'
import Programacoes from './programacoes'
import Cid from './cid'
import MotivoTransporte from './motivo_transporte'
import TipoTransporte from './tipo_transporte'
import TipoAuxilioLocomocao from './tipo_auxilio_locomacao'
import TipoDomicilio from './tipo_domicilio'
import TipoUnidadeSaude from './tipo_unidade_saude'
import TipoVeiculo from './tipo_veiculo'
import Veiculo from './veiculo'
import Paciente from './paciente'
import Cbo from './cbo'
import Municipio from './municipio'
import Uf from './uf'
import NaturezaTransporte from './natureza_transporte'
import SituacaoTransporte from './situacao_transporte'
import UnidadeSaude from './unidade_saude'
import Profile from './profile'
import PerfilUsuario from './perfil_usuario'
import Produto from './produto'
import TipoCuidado from './tipo_cuidado'
// import Estabelecimento from './estabelecimento'
// import TipoEstabelecimento from './tipo_estabelecimento'
import CategoriaProduto from './categoria_produto'
import TipoUnidadeMedida from './tipo_unidade_medida'
import Configuracao from './configuracao'
import Cancelamento from './cancelamento'
import Classificacao from './classificacao'
import Log from './log'
import Equipe from './equipe'
import TipoProfissional from './tipo_profissional'
import Tripulacao from './tripulacao'
import Tripulante from './tripulante'
import Frota from './frota'
import PosicaoVeiculo from './posicao_veiculo'
import Condutor from './condutor'
import CanceladoFinalizado from './cancelado_finalizado'
import Ticket from './ticket'
import GrupoChecklist from './grupo_checklist'
import ItemChecklist from './item_checklist'
import RespostaChecklist from './resposta_checklist'
import Checklists from './checklists'
import EquipeEmbarcada from './equipe_embarcada'
import Tarm from './solicitacao_tarm'
import TipoDesfecho from './tipo_desfecho'
import Regiao from './regiao'
import GerenciarJ4 from './gerenciar_j4'
import Rih from './rih'
import TestePerformance from './teste_permonface'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    User,
    Solicitacao,
    TipoAvaliacaoClinica,
    TipoAcompanhante,
    Programacoes,
    Cid,
    MotivoTransporte,
    TipoTransporte,
    TipoAuxilioLocomocao,
    TipoDomicilio,
    TipoUnidadeSaude,
    TipoVeiculo,
    Veiculo,
    Paciente,
    Cbo,
    Municipio,
    Uf,
    NaturezaTransporte,
    SituacaoTransporte,
    UnidadeSaude,
    Profile,
    PerfilUsuario,
    Produto,
    TipoCuidado,
    TipoDesfecho,
    // Estabelecimento,
    // TipoEstabelecimento,
    CategoriaProduto,
    TipoUnidadeMedida,
    Configuracao,
    Cancelamento,
    Classificacao,
    Log,
    Equipe,
    TipoProfissional,
    Tripulacao,
    Tripulante,
    Frota,
    PosicaoVeiculo,
    Condutor,
    CanceladoFinalizado,
    Ticket,
    GrupoChecklist,
    ItemChecklist,
    RespostaChecklist,
    Checklists,
    EquipeEmbarcada,
    Tarm,
    Regiao,
    GerenciarJ4,
    Rih,
    TestePerformance,

    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const perfil_id = localStorage.getItem('perfil_id');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated &&
    (
      !(['/login'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0]))
    )
  ) {
    next('/login');

    return true;
  }

  if (authenticated && perfil_id == 20 && !(['profile', 'condutor', 'index', 'view', 'condutor/index', 'visualizar-solicitacao', 'condutor/checklist', 'checklist',].includes(to_splited[0]))) {
    next('/condutor/index');
    return true;
  }

  if (to.meta.perfil_id) {
    if (!to.meta.perfil_id.includes(perfil_id)) {
      next('/');
    }
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
