<template>
  <div>
    <button class="btn btn-primary" @click="getPerformance">
      Simular Performance
    </button>

    <div class="form-group mt-2" v-if="result">
      <h3>Resultados da Simulação</h3>
      <!-- <p><strong>URL:</strong> {{ result.url }}</p> -->
      <p><strong>Status:</strong> {{ result.status }}</p>
      <p><strong>Tempo de Execução:</strong> {{ result.execution_time }} ms</p>
      <p><strong>Hora de Início:</strong> {{ result.start_time }}</p>
      <p><strong>Hora de Término:</strong> {{ result.end_time }}</p>
      <p><strong>Usuário:</strong> <pre>{{ result.user.usuario_nome }}</pre></p>
      <p><strong>IP:</strong> <pre>{{ result.ip }}</pre></p>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      filters: {
        url: "https://api.tihrio.ares-saude.com.br/api/v1/solicitacoes/44629?with[]=paciente&with[]=solicitante&with[]=unidade&with[]=avaliacoes_clinicas&with[]=desfechos&with[]=cids&with[]=tipo_transporte&with[]=motivo_transporte&with[]=acompanhante&with[]=origem&with[]=destino&with[]=cancelamento&with[]=usuario_aprovacao&with[]=classificacao&with[]=usuario_cancelamento&with[]=usuario_solicitacao&with[]=usuario_programacao&with[]=usuario_finalizacao&with[]=intercorrencias&with[]=responsavel_programacao&with[]=usuario_rih",
      },
      solicitacoes: [],
      result: null,
    };
  },
  components: {
    BaseCrud,
    vSelect,
  },

  methods: {
    getPerformance() {
      const self = this;

      if (!self.filters.url) {
        alert("Por favor, insira uma URL válida.");
        return;
      }

      let api = self.$store.state.api + "solicitacoes/testes";

      const requestData = {
        url: self.filters.url,
      };

      self.$loading(true);

      axios
        .post(api, requestData)
        .then((response) => {
          const data = response.data;
          self.result = data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.message, "error");
          self.$loading(false);
        });
    },
  },
  mounted() {},
};
</script>
