import Crud from '../views/Cancelamento/Crud'
import Entity from '../views/Cancelamento/Add'

let routes = {
    path: '/cancelamentos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/cancelamentos/add',
            name: 'NewCancelamento',
            component: Entity
        },
        {
            path: '/cancelamentos/edit/:id?',
            name: 'EditCancelamento',
            component: Entity
        },
        {
            path: '/cancelamentos',
            name: 'list-cancelamentos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Cancelamentos',
                breadcrumb: [
                    {
                        text: 'Cancelamento',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
