import Crud from '../views/TipoDomicilio/Crud'
import Entity from '../views/TipoDomicilio/Add'

let routes = {
    path: '/tipo_domicilio',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/tipo_domicilio/add',
            name: 'NovoAcompanhante',
            component: Entity
        },
        {
            path: '/tipo_domicilio/edit/:id?',
            name: 'EditarAcompanhante',
            component: Entity
        },
        {
            path: '/tipo_domicilio',
            name: 'list-tipo-domicilio',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Tipos de Endereços',
                breadcrumb: [
                    {
                        text: 'Tipos de Endereços',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
