import Entity from '../views/Ticket/View'

let routes = {
    path: '/ticket',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/ticket',
            name: 'list-ticket',
            component: Entity,
            meta: {
                pageTitle: 'Abrir Chamado',
                breadcrumb: [
                    {
                        text: 'Chamados',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
