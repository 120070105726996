import Crud from '../views/RespostaChecklist/Crud'
import Entity from '../views/RespostaChecklist/Add'

let routes = {
    path: '/respostachecklists',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/respostachecklists/add',
            name: 'NewCbo',
            component: Entity
        },
        {
            path: '/respostachecklists/edit/:id?',
            name: 'EditCbo',
            component: Entity
        },
        {
            path: '/respostachecklists',
            name: 'list-respostachecklists',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Resposta de Checklists',
                breadcrumb: [
                    {
                        text: 'Resposta Checklists',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
