<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{ regioes.regiao_id ? "Editar" : "Nova" }}
                Região
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Nome:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="regioes.nome"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="RaioKm">
                        <span class="required">*</span> Raio em km:
                        <span id="raioKmValue">{{ raio_km }}</span> km
                      </label>
                      <input
                        autocomplete="new-password"
                        type="range"
                        id="RaioKm"
                        class="form-range"
                        v-model="raio_km"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <GmapMap
                        @click="mapClicked"
                        :center="center"
                        :zoom="13"
                        style="width: 100%; height: 500px"
                      >
                        <GmapMarker
                          :position="markerPosition"
                          @dragend="markerDragged"
                          draggable
                        />
                        <GmapCircle
                          :center="circleCenter"
                          :radius="raio_km * 1000"
                          :options="circleOptions"
                        />
                      </GmapMap>
                    </div>
                  </div>
                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong>Campos obrigatórios</strong>
                  </span>
                  <div class="block text-right">
                    <router-link to="/regioes" class="btn btn-back mr-1">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
// import { GmapMap, GmapMarker } from "vue2-google-maps";

export default {
  data() {
    return {
      regioes: {
        nome: "",
        latitude: "",
        longitude: "",
        raio_km: "",
      },
      center: { lat: 0, lng: 0 },
      markerPosition: null,
      latitude: null,
      longitude: null,
      circleCenter: { lat: 0, lng: 0 }, // Centro do círculo
      circleOptions: {
        fillColor: "#00FF00",
        fillOpacity: 0.3,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
      },
      raio_km: 0,
    };
  },
  computed: {},
  methods: {
    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
      this.circleCenter = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    markerDragged(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
      this.circleCenter = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "regioes";
      let method = "POST";

      if (self.regioes.regiao_id) {
        method = "PUT";
        api += "/" + self.regioes.regiao_id;
      }

      const data = {
        nome: this.regioes.nome,
        latitude: this.latitude,
        longitude: this.longitude,
        raio_km: this.raio_km,
        regiao_id: this.regioes.regiao_id,
        _method: method,
      };

      axios
        .post(api, data)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/regioes");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getLocalicaoAtual() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.markerPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.regioes.latitude = position.coords.latitude;
          this.regioes.longitude = position.coords.longitude;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;

          this.circleCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.raio_km = 5;
        });
      } else {
        console.error("A geolocalização não é suportada por este navegador.");
      }
    },
    getRegioes: function (id) {
      const self = this;
      const api = self.$store.state.api + "regioes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.regioes = response.data.data[0];
          const latitude = parseFloat(response.data.data[0].latitude);
          const longitude = parseFloat(response.data.data[0].longitude);
          let raio_km = response.data.data[0].raio_km;
          raio_km = parseFloat(raio_km);
          if (!isNaN(latitude) && !isNaN(longitude) && !isNaN(raio_km)) {
            self.center = { lat: latitude, lng: longitude };
            self.markerPosition = { lat: latitude, lng: longitude };
            self.regioes.latitude = latitude;
            self.regioes.longitude = longitude;
            self.raio_km = raio_km;
            self.circleCenter = { lat: latitude, lng: longitude };
          } else {
            console.error("Coordenadas inválidas");
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getLocalicaoAtual();

    let id = self.$route.params.id;
    if (id) {
      self.getRegioes(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

[dir] .form-range {
  /* padding: 0.438rem 1rem; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.form-range {
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>