<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-2">
        <label>Número da Solicitação</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.numero_solicitacao"
        />
      </div>
      <div class="col-md-2">
        <label>Data da Solicitação</label>
        <input
          type="date"
          class="form-control"
          v-model="filters.data_solicitacao"
        />
      </div>
      <div class="col-md-2">
        <label>Data do Transporte</label>
        <input
          type="date"
          class="form-control"
          v-model="filters.data_procedimento"
        />
      </div>
      <div class="col-md-3">
        <label>Tipo de Transporte</label>
        <v-select
          :options="tipos_transporte"
          label="tipo_transporte_nome"
          v-model="filters.tipo_transporte_id"
          :reduce="(tipo) => tipo.tipo_transporte_id"
        ></v-select>
      </div>
      <div class="col-md-3">
        <label>Nome do Paciente</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.nome_paciente"
        />
      </div>
      <div class="col-md-4 mt-1">
        <label>Veículo</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.nome_veiculo"
        />
      </div>
      <div class="col-md-4 mt-1">
        <label>Origem</label>
        <input type="text" class="form-control" v-model="filters.origem" />
      </div>
      <div class="col-md-4 mt-1">
        <label>Destino</label>
        <input type="text" class="form-control" v-model="filters.destino" />
      </div>
      <div class="col-md-3 mt-1">
        <label>Status</label>
        <select class="form-control" v-model="filters.status_solicitacao">
          <option value=""></option>
          <option value="3">Finalizado</option>
          <option value="4">Cancelado</option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2 d-flex justify-content-between">
        <button class="btn btn-primary" @click="getCanceladosFinalizados">
          Filtrar
        </button>
        <button
          class="col-md-12 ml-1 btn btn-success text-content-right"
          data-toggle="modal"
          data-target="#modalExport"
        >
          Exportar Solicitações em Excel
        </button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card content-crud">
          <div class="ibox">
            <div class="ibox-title" style="padding: 16px">
              <h3>Finalizados e Cancelados</h3>
              <div
                class="modal fade"
                id="modalExport"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalExportTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Exportar
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-content modal-lg mt-1">
                      <div
                        class="alert alert-warning"
                        role="alert"
                        v-if="!filters.data_inicio && !filters.data_fim"
                      >
                        Para exportar todos os registros de finalizados e
                        cancelados, basta não definir nenhum intervalo de datas
                        e proceder com a exportação.
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="d-flex justify-content-between">
                        <div class="col-md-6 mb-1">
                          <label>Data de Ínicio</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="filters.data_inicio"
                          />
                        </div>
                        <div class="col-md-6 mb-1">
                          <label>Data do Fim</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="filters.data_fim"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="exportToExcel"
                      >
                        Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ibox-content">
              <div class="table-responsive" style="padding: 10px">
                <table
                  class="VueTables__table table table-striped table-bordered table-hover"
                  style="padding: 10px"
                >
                  <thead>
                    <tr>
                      <th scope="col">NÚMERO SOLICITACAO</th>
                      <th scope="col">DATA DA SOLICITAÇÃO</th>
                      <th scope="col">DATA DO TRANSPORTE</th>
                      <th scope="col">TIPO DE TRANSPORTE</th>
                      <th scope="col">NOME PACIENTE</th>
                      <th scope="col">ORIGEM</th>
                      <th scope="col">DESTINO</th>
                      <th scope="col">VEÍCULO</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">RETORNO?</th>
                      <th scope="col">AÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="solicitacao in solicitacoes"
                      :key="solicitacao.numero_solicitacao"
                    >
                      <td>{{ solicitacao.numero_solicitacao }}</td>
                      <td>{{ solicitacao.data_solicitacao_f }}</td>
                      <td>{{ solicitacao.data_procedimento_f }}</td>
                      <td>
                        {{ solicitacao.tipo_transporte.tipo_transporte_nome }}
                      </td>
                      <td>
                        {{
                          solicitacao.paciente
                            ? solicitacao.paciente.nome_paciente
                            : "Não informado"
                        }}
                      </td>
                      <td>
                        {{
                          solicitacao.origem &&
                          solicitacao.origem.tipo_estabelecimento_id === 9
                            ? "Atendimento em via pública"
                            : solicitacao.origem &&
                              solicitacao.origem.estabelecimento_nome
                            ? solicitacao.origem.estabelecimento_nome
                            : "Não informado"
                        }}
                      </td>
                      <td>
                        {{
                          solicitacao.destino
                            ? solicitacao.destino.estabelecimento_nome
                            : "Não informado"
                        }}
                      </td>
                      <td>
                        {{
                          solicitacao.veiculo
                            ? solicitacao.veiculo.veiculo.nome_veiculo
                            : "Não informado"
                        }}
                      </td>
                      <td v-html="status_solicitacao(solicitacao)"></td>
                      <td v-html="retorno(solicitacao)"></td>
                      <td>
                        <router-link
                          :to="'/programacoes/programacao/' + solicitacao.id"
                          title="Visualizar"
                          class="btn btn-crud view"
                        >
                          <i class="fas fa-eye"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-md-6 mt-1 mx-auto d-flex justify-content-center align-items-center"
                >
                  <pagination
                    :records="total"
                    :options="options"
                    :per-page="15"
                    @paginate="getCanceladosFinalizados"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { Pagination } from "vue-pagination-2";
export default {
  data: function () {
    return {
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      autoRefreshAtivado: false,
      pages: [],
      solicitacoes: [],
      showFinished: false,
      filters: { data_solicitacao: null },
      tipos_transporte: [],
    };
  },
  components: {
    BaseCrud,
    vSelect,
    Pagination,
  },

  methods: {
    exportToExcel() {
      const self = this;
      const dataInicio = self.filters.data_inicio;
      const dataFim = self.filters.data_fim;

      if (dataInicio || dataFim) {
        if (dataInicio && dataFim) {
          var url = `${self.$store.state.url}cancelados_finalizados/exportar?data_inicio=${dataInicio}&data_fim=${dataFim} 23:59:59`;
          window.open(url, "_blank").focus();
        } else {
          self.$message(
            null,
            "Por favor, preencha ambos os campos de data.",
            "error"
          );
          return;
        }
      } else {
        var url = `${self.$store.state.url}cancelados_finalizados/exportar`;
        window.open(url, "_blank").focus();
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    getCanceladosFinalizados(page) {
      const self = this;

      self.page = page || 1;

      let api =
        self.$store.state.api +
        "solicitacoes/cancelados_finalizados?page=" +
        self.page;

      self.$loading(true);
      axios
        .post(api, self.filters)
        .then((response) => {
          self.solicitacoes = response.data.data;
          self.total = response.data.total;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    status_solicitacao: function (row) {
      if (row.status_solicitacao == 0) {
        return "<h5><span class='badge badge-warning'>Solicitado</span></h5>";
      }
      if (row.status_solicitacao == 1) {
        return "<h5><span class='badge badge-info'>Aguardando</span></h5>";
      }
      if (row.status_solicitacao == 2) {
        return "<h5><span class='badge badge-primary'>Despachado</span></h5>";
      }
      if (row.status_solicitacao == 3) {
        return "<h5><span class='badge badge-success'>Finalizado</span></h5>";
      }
      if (row.status_solicitacao == 4) {
        return "<h5><span class='badge badge-danger'>Cancelado</span></h5>";
      }
    },
    retorno: function (row) {
      if (row.retorno == 1) {
        return "<h5><span class='badge badge-primary'>Sim</span></h5>";
      }
      if (row.retorno == 0) {
        return "<h5><span class='badge badge-secondary'>Não</span></h5>";
      }
    },
    getTiposTransporte: function () {
      const self = this;
      let api =
        self.$store.state.api + "tipo_transportes?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_transporte = response.data.data;

          if (self.tipos_transporte.length == 1) {
            self.entity.tipo_transporte_id =
              self.tipos_transporte[0].tipo_transporte_id;

            if (self.tipos_transporte[0].tipo_solicitacao == 0) {
              self.getMotivos();
              self.inverse = true;
              self.estilo_transporte = 0;
              self.page = 2;
              self.setPages();
            }
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    atualizarProgramacao(valor) {
      this.autoRefreshAtivado = valor;
      if (valor) {
        // this.iniciarTemporizadorAutoRefresh();
      } else {
        // this.pararTemporizadorAutoRefresh();
      }
    },
    iniciarTemporizadorAutoRefresh() {
      if (this.autoRefreshAtivado) {
        this.temporizadorAutoRefresh = setInterval(() => {
          this.$refs.programacaoCrud.$refs.table.refresh();
        }, 10000);
      }
    },
    pararTemporizadorAutoRefresh() {
      clearInterval(this.temporizadorAutoRefresh);
    },
    filtered_dp(date) {
      this.data_procedimento = date;
    },
    filtered_ds(date) {
      this.data_solicitacao = date;
    },
    showFinisheds(res) {
      const self = this;
      self.$refs.programacaoCrud.$refs.table.refresh();
    },
    approving(props, row, index) {
      const self = this;
      this.$router.push("/programacoes/programacao/" + props.row.id);
    },
  },
  mounted() {
    this.iniciarTemporizadorAutoRefresh();
    this.getCanceladosFinalizados();
    this.getTiposTransporte();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>