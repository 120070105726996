import Crud from '../views/Log/Crud'
import View from '../views/Log/View'

let routes = {
    path: '/logs_genericos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/logs_genericos/view/:id?',
            name: 'ViewLog',
            component: View
        },
        {
            path: '/logs_genericos',
            name: 'list-logs_genericos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Logs',
                breadcrumb: [
                    {
                        text: 'Logs',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
